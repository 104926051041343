import React from "react";
import { Box, Flex} from "@chakra-ui/react";
import Sidebar from "./sidebar";
import Image from "gatsby-image"
import LinkCustom from "./transitions/linkCustom";

const MenuItems = ({ children, to, slug }) => {
  return (
    <Box 
      _hover={{
        fontWeight: '800'
      }}>
    <LinkCustom url={`/${to === "index" ? "" : to}`} effect="fade" lenght={0.8}
      style={{
        textDecoration: to === slug ? 'underline' : 'none'
      }}
    >
      <Box fontStyle="italic" textTransform="uppercase" fontSize={22} mt={[1, 1, 1, 1]} mr={8} display="block" color="brandYellow.500">
        {children}
      </Box>
    </LinkCustom >
    </Box>

  )
};


const Navbar = ({ bgImage, data, slug, ...rest }) => {
  const [show] = React.useState(data.showBurgerBarOnly);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      paddingTop={6}
      color="brandYellow.500"
      {...rest}
    >
      <Sidebar bgImage={bgImage}
        display={['block', 'block', 'block', show ? "block" : 'none']}
        navbarItems={data.navbarItems}
        logo={data.logo}>
      </Sidebar>
      <Box
        display={["none", "none", "none", !show ? "flex" : "none"]}
        width={["full", "full", "full", "auto"]}
        alignItems="center"
        flexGrow={1}
      >
        <LinkCustom url="/" effect="fade" lenght={0.8}>
          <Box height="50px" width="50px" marginRight={5}>
            <Image fluid={data.logo.fluid} />
          </Box>
        </LinkCustom>
        {data.navbarItems.map((item, index) => (
          item.linkTo && 
            <MenuItems slug={slug} to={item.linkTo.slug} key={index}>{item.linkTo.title}</MenuItems>
        ))}
      </Box>
    </Flex>
  );
};

export default Navbar;
