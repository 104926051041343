


import React, {}  from "react"

import { Container, Box } from "@chakra-ui/react"
import ConditionalBackground from "./conditionalBackground"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Footer from "./footer"
import Header from "./header"
import Navbar from "./navbar"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async";
// import DataLayer from "./dataLayer"
const googleKey = process.env.GATSBY_GOOGLE_ANALYTICS_KEY

const Layout = ({ showFooter, navbar, bgImage, titlePosition, children, slug, title, metaImage, metaTitle, metaDescription, ...rest }) => {  
  var siteURL = 'https://studio.ai'
  var pageURL = slug === "/" ? siteURL : siteURL + "/" + slug
  var secureImageURL = 'https:' + metaImage.src
  var imageURL = 'https:' + metaImage.src

  return (
    <>
      <Helmet
        key="app-head"
        titleTemplate="%s"
        defaultTitle="STUDIO 44"
      >
        <html lang="en" />

        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title>{title ? title : metaTitle}</title>

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta name="twitter:description" content={metaDescription}/>
          <meta name="twitter:title" content={metaTitle}/>
          <meta name="twitter:image" content={secureImageURL}/>
          <meta name="twitter:image:src" content={secureImageURL}/>          
          <meta name="twitter:card" content="summary_large_image"/>
          
          <meta property="og:title" content={metaTitle}/>
          <meta property="og:type" content="website"/>
          <meta property="og:site_name" content={metaTitle}/>
          <meta property="og:description" content={metaDescription}/>
          <meta property="og:url" content={pageURL}/>
          <meta property="og:image" content={imageURL}/>
          <meta property="og:image:secure_url" content={secureImageURL}/>
          <meta property="og:image:width" content={metaImage.width}/>
          <meta property="og:image:height" content={metaImage.height}/>
          
          <meta itemprop="title" content={metaTitle} />
          <meta itemprop="name" content={metaTitle}/>
          <meta itemprop="description" content={metaDescription}/>
          <meta itemprop="image" content={secureImageURL}/>          
      </Helmet>
      {/* <DataLayer googleKey={googleKey} page={slug} /> */}
      <GatsbySeo
        title={`STUDIO 44 ${title ? "| " + title : ""}`}
        description={metaDescription}
        // canonical={pageURL}
        openGraph={{
          type: 'website',
          url: pageURL,
          title: metaTitle,
          description: metaDescription,          
          image: {
            '@type': 'ImageObject',
              url: secureImageURL,
              width: metaImage.width,
              height: metaImage.height,
              alt: metaTitle,
            },
          site_name: 'STUDIO 44',
        }}
        // twitter={{
        //   handle: '@handle',
        //   site: '@site',
        //   cardType: 'summary_large_image',
        // }}
      />
      <Box id="layout" {...rest} >
        <ConditionalBackground id="condBack" bgImage={bgImage}  >
          <Container id="layoutContainer"
            maxW={["95%", "95%", "95%", "95%", "95%"]}
            marginBottom={2}
            minHeight="100vh"
          >
            <Navbar data={navbar} slug={slug} bgImage={bgImage} />
            <Header title={title} titlePosition={titlePosition} />
            {children}
            {(showFooter == null || showFooter) &&
              <Footer />
            }
          </Container>
        </ConditionalBackground>
      </Box>
    </>

  )
}

Layout.propTypes = {
  showFooter: PropTypes.bool,
  title: PropTypes.string,
  metaTitle: PropTypes.string
};

Layout.defaultProps = {
  showFooter: "true",
}

export default Layout
