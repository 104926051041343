import { Button as ChakraButton } from "@chakra-ui/react"
import React from "react"

const Button = ({ buttonText, ...rest }) => {
  return (

    <ChakraButton variant="outline" fontWeight={800} fontFamily="Project44Lite" colorScheme="brandYellow" height="65px" {...rest} pb={0} paddingTop={[0, 0, 0, 0]} fontSize={[30, 30, 40, 40]}>
      {buttonText}
    </ChakraButton>
  )
}

export default Button
