import { Box,Text, Center, VStack, Container} from "@chakra-ui/react"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
    return (
        <Container id="footer" height="100px" marginTop={10} marginBottom={10} variant="Footer">
            <Center width="100%">
                <VStack>
                    <Box >
                        <a href="https://uk.linkedin.com/company/studio44io" rel="noopener noreferrer" aria-label="PROJECT 44 LinkedIn" target="_blank"> <FontAwesomeIcon icon={faLinkedinIn} /></a>
                    </Box>
                    <Box>
                        <Text  fontSize={[12,12,16,16]} >STUDIO 44 - 2021 All Rights Reserved</Text>
                        
                    </Box>
                </VStack>
            </Center>
        </Container>
    )
}

export default Footer
