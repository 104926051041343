import React from "react"
import { Box } from "@chakra-ui/react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"

const ConditionalBackground = ({ children, bgImage, ...rest }) => {
    return (
        <Box
            id="condBackgroundBox"
            {...rest}
        >
            {bgImage ?
                <BackgroundImage
                    style={{
                        content: '',
                        height: '100%',
                        width: '100%',
                        backgroundAttachment: 'fixed',
                        left: '0',
                        top: '0',
                        backgroundSize: 'cover',
                        display: `absolute`
                    }}
                    fluid={bgImage.image.fluid}
                >
                    {children}
                </BackgroundImage>
                :
                { children }
            }
        </Box>
    )
}

ConditionalBackground.propTypes = {
    children: PropTypes.node.isRequired,
    bgImage: PropTypes.object
};

ConditionalBackground.defaultProps = {
    bgImage: null
}

export default ConditionalBackground
