import React from "react";
import { useDisclosure, Box, Drawer, DrawerContent, DrawerHeader, DrawerOverlay, DrawerBody, DrawerCloseButton, HStack } from "@chakra-ui/react";
import { Link } from "gatsby";
import ConditionalBackground from "./conditionalBackground";
import LinkCustom from "./transitions/linkCustom";


const Sidebar = ({ bgImage, logo, navbarItems, ...rest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    console.log("NAVBAR ITEMS", navbarItems)
    return (
        
        <Box {...rest}>
            <Box ml={1} display={['block', 'block', 'block', 'block']} onClick={onOpen}>
                <svg
                    fill="yellow"
                    width="20px"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
            </Box>
            <Drawer   initialFocusRef={0} placement={"left"} onClose={onClose} isOpen={isOpen} >
            
                <DrawerOverlay>
                
                    <DrawerContent backgroundColor={"transparant"}>
                        <ConditionalBackground id="condBack"  height="100%" bgImage={bgImage}  >
                        
                            
                            <DrawerHeader fontFamily="Project44Lite" letterSpacing=".1em" borderBottomWidth="1px">
                            <HStack width="100%">
                                <LinkCustom url="/" effect="fade" lenght={0.8}>
                                <Box color="brandYellow.300" width="100%">STUDIO 44</Box>
                                {/* <Link to="/">
                                    <Box height="50px" width="50px" marginRight={5}>
                                        <Image fluid={logo.fluid}/>
                                    </Box>
                                    </Link> */}
                                    </LinkCustom>
                                <DrawerCloseButton color="brandYellow.300"  alignItems="right" marginTop={5} backgroundColor={"blue"}/>
                                </HStack>
                            </DrawerHeader>
                        
                        <DrawerBody  backgroundColor={"transparant"} color="brandYellow.300">
                            
                            {navbarItems.map((item, index) => (
                                item.linkTo && item.linkTo.slug  && (
                                    <Box  key={index} paddingTop={4}>   
                                    <Link fontStyle="italic" to={`/${item.linkTo.slug === "index" ? "" : item.linkTo.slug}`}>
                                        <Box  textTransform="uppercase" fontSize={20}>{item.linkTo.title}</Box>
                                    </Link>
                                    </Box>
                                )
                            ))}
                            
                            {/* <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p> */}
                        </DrawerBody>
                        </ConditionalBackground>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </Box>
        
    )
    
}


export default Sidebar;